<template>
  <div class="Help" id="Help" name="Help">
    <MyMenu :menuname="'index'"></MyMenu>
    <div class="w1200">
      <div class="Help-leftNav">
        <div class="leftNav-tit">服务中心</div>
        <h2>
          <i class="el-icon-location"></i>
          协议说明
        </h2>
        <ul>
          <li @click="Service" :class="ishover == 'Service' ? 'hover' : ''">租赁服务协议</li>
          <li @click="frameService" :class="ishover == 'frameService' ? 'hover' : ''">租赁服务协议</li>
          <li @click="UserAggrement" :class="ishover == 'UserAggrement' ? 'hover' : ''">用户信息查询授权书</li>
          <li @click="PrivacyAggrement" :class="ishover == 'PrivacyAggrement' ? 'hover' : ''">隐私协议</li>
        </ul>
        <h2>
          <i class="el-icon-setting"></i>
          企业介绍
        </h2>
        <ul>
          <li @click="About" :class="ishover == 'About' ? 'hover' : ''">企业概况</li>
        </ul>
        <h2>
          <i class="el-icon-menu"></i>
          租赁说明
        </h2>
        <ul>
          <li @click="notice" :class="ishover == 'notice' ? 'hover' : ''">下单须知</li>
          <li @click="explain" :class="ishover == 'explain' ? 'hover' : ''">租赁说明</li>
        </ul>
      </div>
      <div class="Help-rightPage">
        <!-- 租赁服务协议 -->
        <div class="preview" ref="printContent" v-if="ishover == 'Service'">
          <previewPDF :pdfSrc="pdfSrc" :pageCount="pageCount"></previewPDF>
        </div>

        <!-- 租赁服务协议 -->
        <div class="preview" ref="printContent" v-if="ishover == 'frameService'">
          <previewPDF :pdfSrc="pdfSrc" :pageCount="pageCount"></previewPDF>
        </div>

        <!-- 租赁服务协议 -->
        <div class="preview" ref="printContent" v-if="ishover == 'PrivacyAggrement'">
          <previewPDF :pdfSrc="pdfSrc" :pageCount="pageCount"></previewPDF>
        </div>

        <div class="UserAggrement" v-if="ishover == 'UserAggrement'">
          <UserAggrement></UserAggrement>
        </div>

        <!-- 关于我们 -->
        <div class="About" v-if="ishover == 'About'">
          <About></About>
        </div>

        <!-- 租赁说明 -->
        <div class="explain" v-if="ishover == 'explain'" style="text-align: center">
          <img src="https://file.juuzu.com/%E7%A7%9F%E8%B5%81%E8%AF%B4%E6%98%8E.jpg" />
        </div>

        <!-- 下单须知 -->
        <div class="notice" v-if="ishover == 'notice'" style="text-align: center">
          <img src="https://file.juuzu.com/%E4%B8%8B%E5%8D%95%E9%A1%BB%E7%9F%A5.jpg" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import previewPDF from './item/previewPDF.vue'
import UserAggrement from './item/UserAggrement.vue'
import About from './item/About.vue'

export default {
  components: {
    previewPDF,
    UserAggrement,
    About
  },
  data() {
    return {
      ishover: '',
      pdfSrc: ''
    }
  },
  watch: {
    $route(to) {
      // 对路由变化作出响应...
      if (to.params.name) {
        this.ishover = to.params.name
        this[to.params.name]()
      } else {
        // this.ishover = 'user'
      }
    }
  },
  mounted() {},

  created() {
    if(this.$route.params.name){
      this.ishover = this.$route.params.name
      this[this.$route.params.name]()
    }
  },
  methods: {
    // 服务协议
    Service() {
      this.$router.push({ path: '/help/Service' })
      this.pdfSrc = 'https://file.juuzu.com/%E9%AB%98%E7%93%B4%E7%9B%9B%E7%A7%9F%E8%B5%81%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE20230725.pdf'
      this.pageCount = 14
    },
    // 服务协议
    frameService() {
      this.$router.push({ path: '/help/frameService' })
      this.pdfSrc = 'https://file.juuzu.com/%E6%A1%86%E6%9E%B6%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE-1.pdf'
      this.pageCount = 14
    },

    // 用户授权书
    UserAggrement() {
      this.$router.push({ path: '/help/UserAggrement' })
    },

    // 租赁说明
    explain() {
      this.$router.push({ path: '/help/explain' })
    },

    // 关于我们
    About() {

      this.$router.push({ path: '/help/About' })
    },

    // 下单须知
    notice() {
      this.$router.push({ path: '/help/notice' })
    },

    // 隐私协议
    PrivacyAggrement() {
      this.$router.push({ path: '/help/PrivacyAggrement' })
      this.pdfSrc = 'https://file.juuzu.com/%E5%B7%A8%E6%82%A0%E7%A7%9F%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96%20.pdf'
      this.pageCount = 14
    }
  }
}
</script>
<style scoped>
@import '../../assets/css/index.css';
</style>
